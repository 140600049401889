<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-shape-form" @submit.prevent="submit">
    <div class="left">
      <div class="form-row code">
        <p-code v-model="template" :read-only="!$hasPermission('templates')" :language="'handlebars'"></p-code>
      </div>
    </div>
    <div class="right">
      <div class="form-row thumbnail">
        <div class="thumbnail-image-wrapper">
          <img :src="thumbnailUrl" class="thumbnail-image" />
        </div>
        <!-- <p-file :label="'Thumbnail'" accept="image/png" @input="thumbnailSelected"></p-file> -->
      </div>
      <div class="form-row name">
        <p-text-field v-model="name" :disabled="!$hasPermission('templates')" :label="'Name'" autocomplete="off" />
        <div v-if="!$v.name.required" class="error">Name is required</div>
      </div>
      <div class="form-row description">
        <p-text-field v-model="description" :disabled="!$hasPermission('templates')" :label="'Description'" autocomplete="off" />
      </div>
      <div class="form-row tags">
        <p-multiselect
          v-model="tags"
          :multiple="true"
          :taggable="true"
          :disabled="!$hasPermission('templates')"
          label="Tags"
          placeholder=""
          tag-placeholder=""
          @tag="tagAdded"
        ></p-multiselect>
      </div>
      <div v-if="isRequestFailed" class="error">Failed to save a shape. Check your input and try again.</div>
      <div class="form-row submit">
        <p-button v-if="$hasPermission('templates')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
// import File from '@/components/common/File';
import Code from '@/components/common/Code';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    // 'p-file': File,
    'p-multiselect': Multiselect,
    'p-code': Code
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        description: this.item.description,
        tags: this.item.tags,
        template: this.item.template || '',

        thumbnailUrl: this.item.thumbnailUrl
      };
    }

    throw new Error('Create is not suppoorted');
    // return {
    //   name: '',
    //   description: '',
    //   tags: [],
    //   template: '',

    //   thumbnailUrl: null
    // };
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        name: this.name && this.name.trim(),

        description: this.description && this.description.trim(),
        tags: this.tags,
        template: this.template
      });

      this.$v.$reset();
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    }
    // thumbnailSelected(image) {
    //   const reader = new FileReader();

    //   reader.addEventListener(
    //     'load',
    //     () => {
    //       this.thumbnailUrl = reader.result;
    //     },
    //     false
    //   );

    //   reader.readAsDataURL(image);
    // }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-shape-form {
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  display: grid;

  > div {
    min-width: 0;
    min-height: 0;
  }
  .left {
    grid-column: 2/3;
    grid-row: 1/2;

    background: var(--theme-surface);
    padding: 0.1rem;
    overflow: hidden;

    .code {
      height: 100%;
    }
  }
  .right {
    grid-column: 1/2;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;

    > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .submit {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .thumbnail {
    .thumbnail-image-wrapper {
      width: 100%;
      height: 250px;
      border: 1px solid var(--theme-on-background-dark);
      border-radius: 2px;
      margin-bottom: 1.5rem;
      display: flex;
    }
    .thumbnail-image {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: auto;
      padding: 3px;
      box-sizing: border-box;
    }
  }
  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
