<template>
  <p-edit />
</template>

<script>
import EditShape from './../components/shapes/EditShape.vue';

export default {
  components: {
    'p-edit': EditShape
  }
};
</script>
